import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { 
   Box, 
   Button,
   Center,
   Input,
   InputLeftElement,
   InputGroup,
   Spinner,
   Accordion,
   AccordionItem,
   AccordionButton,
   AccordionPanel,
   AccordionIcon,
   Select,
   useToast,
} from "@chakra-ui/react"
import { Link as GatsbyLink } from 'gatsby'
import { AiOutlineSearch } from "react-icons/ai"
import axios  from 'axios'
import Layout from '../components/layout.js';
import {
   Midasi2,
   Midasi3,
   RadioButtonsC4,
   TitleList1,
   CommaInput2,
   SwiperTitleList1,
   SwiperTitleList3
} from '../util/functions.js';

const Test001 = (pageprops) => {



   const [channelKeyword,setChannelKeyword] = useState("");
   const [radioMyStatueWordVal, setRadioMyStatueWordVal] = useState("全て");
   const [radioMyCategoryWord, setRadioMyCategoryWord] = useState("全て");

   // const [MyStatueWord, setMyStatueWord] = useState("");
   // const [MyCategoryWord, setMyCategoryWord] = useState("");
   // const [five_ranks,setfive_ranks] = useState({
   //    ranks:{},
   //    finished:false
   // });

   const [ch_viewDownTarget,setch_viewDownTarget] = useState(0);
   const [ch_viewUpTarget,setch_viewUpTarget] = useState(0);
   const [ch_registDownTarget,setch_registDownTarget] = useState(0);
   const [ch_registUpTarget,setch_registUpTarget] = useState(0);

   const [videoKeyword,setVideoKeyword] = useState("");
   const YoutubeCategories = {
      catname:[
         "全て",
         "急上昇",
         "映画とアニメ",
         "自動車と乗り物",
         "音楽",
         "ペットと動物",
         "スポーツ",
         // "ショート ムービー",
         "旅行とイベント",
         "ゲーム",
         // "動画ブログ",
         "ブログ",
         "コメディー",
         "エンターテイメント",
         "ニュースと政治",
         "ハウツーとスタイル",
         "教育",
         "科学と技術",
         // "映画",
         // "アニメ",
         // "アクション/アドベンチャー",
         // "クラシック",
         // "コメディー",
         // "ドキュメンタリー",
         // "ドラマ",
         // "家族向け",
         // "海外",
         // "ホラー",
         // "SF/ファンタジー",
         // "サスペンス",
         // "短編",
         // "番組",
         "予告編",
         "非営利活動"
      ]
   };
   const [radioMyVideoCategoryWord, setradioMyVideoCategoryWord] = useState("全て");

   const [vi_viewDownTarget, setvi_viewDownTarget] = useState(0)
   const [vi_viewUpTarget, setvi_viewUpTarget] = useState(0)
   const [vi_likeDownTarget, setvi_likeDownTarget] = useState(0)
   const [vi_likeUpTarget, setvi_likeUpTarget] = useState(0)
   const [vi_commentDownTarget, setvi_commentDownTarget] = useState(0)
   const [vi_commentUpTarget, setvi_commentUpTarget] = useState(0)

   // const [SpecialWord, setSpecialWord] = useState("");

   const toast = useToast();
   const [reqestform, setreqestform] = useState({
      channelUrl : "",
      Statue : "",
      Category : "",
      isDisable : false
   });


   const updataRequestKeyword = (word,wordtype) => {
      var type="updataRequestKeyword";
      // let postdata = Object.assign({type:type}, {word:word});
      let postdata = {
         type:type, 
         word:word,
         wordtype:wordtype,
      };
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            console.log(res);
         })
         .catch( (error) => {
            console.log(error);
      });
   }

   const updataChannelRequest = (param) => {
      setreqestform({
         ...reqestform,
         isDisable:true
      });
      var type="updataChannelRequest";
      let postdata = Object.assign({type:type}, param);
      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            // console.log(res.data);
            toast({
               title: "リクエスト受付完了",
               description: "リクエストを受付ました。掲載開始までしばらくお待ちください。",
               status: "success",
               duration: 9000,
               isClosable: true,
               position: "top",
            });
            setreqestform({
               ...reqestform,
               isDisable:false
            });
         })
         .catch( (error) => {
            console.log(error);
            setreqestform({
               ...reqestform,
               isDisable:false
            });
      });
   }

   useEffect(() => {

      var type="InfluxTest";
      var userData = "";
      let postdata = Object.assign({type:type}, userData);

      axios.post('https://ytsearch-wordpress.yt-search.net/api/', postdata)
         .then( (res) => {
            console.log(res.data);
            // setData(res.data);
         })
         .catch( (error) => {
            console.log(error);
            // setData("");
      });

   }, []);

   // const channelIdRef = useRef(null);
   // const videoIdRef = useRef(null);
   const [channelIdRef,setchannelIdRef] = useState("");
   const [videoIdRef,setvideoIdRef] = useState("");
   return (
      <>
         <Layout uri={pageprops.uri}>

            <Center mt={"1rem"}>
               <Button 
                  // w="60%"
                  rounded=""
                  as={GatsbyLink} 
                  to={"/channellist"} 
                  m={5} 
                  colorScheme="red"
                  onClick={() =>updataRequestKeyword(channelKeyword,"channel")}
                  // onClick={() =>console.log(ch_viewDownTarget.current.value.replace(/,/g, ''))}
                  // onClick={() =>console.log(ch_viewDownTarget)}
               >
                  全てのチャンネルランキングを見る
               </Button>
            </Center>
            <Midasi2>チャンネル検索</Midasi2>
            <Box bg="#ddd" mx="0.75rem" p="1rem">

               <Midasi3>キーワード</Midasi3>
               <InputGroup>
                  <InputLeftElement
                     children={<AiOutlineSearch />}
                  />
                  <Input 
                     placeholder="キーワード"
                     bg="whiteAlpha.900"
                     onChange={(e) => setChannelKeyword(e.target.value)}
                     defaultValue="" 
                  />
               </InputGroup>


               
               <Box>
                  <Accordion allowMultiple>
                     <AccordionItem border="none">
                        <AccordionButton p="0">
                           <Midasi3>絞り込み</Midasi3>
                           <AccordionIcon boxSize={"2rem"}/>
                        </AccordionButton>
                        <AccordionPanel p={"1rem"}>
                           <Box fontSize="1rem">再生回数</Box>
                           下限
                           {/* <CommaInput1 settaget={setch_viewDownTarget} step={1000}/> */}
                           <CommaInput2 settaget={setch_viewDownTarget} step={1000}/>
                           上限
                           <CommaInput2 settaget={setch_viewUpTarget} step={1000}/>
                           <Box fontSize="1rem" mt="0.5rem">登録者数</Box>
                           下限
                           <CommaInput2 settaget={setch_registDownTarget} step={1000}/>
                           上限
                           <CommaInput2 settaget={setch_registUpTarget} step={1000}/>
                        </AccordionPanel>
                     </AccordionItem>
                  </Accordion>
               </Box>
                  
               

               <Center>
                  <Button 
                     w="60%"
                     rounded="full"
                     as={GatsbyLink} 
                     to={"/channellist"
                        + "?keyword=" + encodeURIComponent(channelKeyword) 
                        + "&creator=" + encodeURIComponent(radioMyStatueWordVal)
                        + "&genre=" + encodeURIComponent(radioMyCategoryWord)
                        + "&vtu=" + ch_viewUpTarget
                        + "&vtd=" + ch_viewDownTarget
                        + "&rtu=" + ch_registUpTarget
                        + "&rtd=" + ch_registDownTarget
                     } 
                     m={5} 
                     colorScheme="red"
                     onClick={() =>updataRequestKeyword(channelKeyword,"channel")}
                     // onClick={() =>console.log(ch_viewDownTarget.current.value.replace(/,/g, ''))}
                     // onClick={() =>console.log(ch_viewDownTarget)}
                  >
                     チャンネル検索
                  </Button>
               </Center>

            </Box>


         </Layout>
      </>
   )
}

export default Test001